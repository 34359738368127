import React from 'react';
import { CardMedia, Box, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import PasswordResetForm from '../components/Auth/ForgotPasswordForm';
import { Header, Content } from '../components/layouts';
import logoPath from '../images/logo.png';

const ForgotPassword = () => {
  const { t } = useTranslation();
  return (
    <>
      <SEO title={t('forgotPassword')} />
      <Header noMenu backArrow />
      <Content md={4} alignMiddle>
        <Box pb={2} align="center">
          <Link to="/">
            <CardMedia
              src={logoPath}
              component="img"
              alt="Pintuna logo"
              style={{ width: '200px' }}
            />
          </Link>
        </Box>
        <Box py={2} align="center">
          <Typography variant="body2" color="textSecondary">
            {t('forgotPasswordNote')}
          </Typography>
        </Box>
        <PasswordResetForm />
      </Content>
    </>
  );
};

ForgotPassword.defaultProps = {};

ForgotPassword.propTypes = {};

export default ForgotPassword;
